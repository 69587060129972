import $ from 'jquery';
import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/src/js/ui/photoswipe-ui-default.js';
const jQuery = $;

(function ($, PhotoSwipe) {
  'use strict';

  var PhotoGallery = function (element, options) {
    this.$element = $(element);
    this.options = $.extend({}, PhotoGallery.DEFAULTS, options);

    this.$items = this.$element.find(this.options.itemSelector);
    if (this.options.itemFilter) {
      this.$items = this.$items.filter(this.options.itemFilter);
    }

    this.$pswpElement = $(this.options.pswpElement);
    var that = this;

    this.pswpItems = this.$items.map(function () {
      var el = $(this);
      var size = el.data('size').split('x');
      return {
        src: el.attr('href'),
        w: size[0],
        h: size[1],
        el: el[0],
        title: el.parent().find(that.options.captionSelector).html()
      };
    }).get();

    var self = this;
    this.$element.on('click', this.options.itemSelector, function (e) {
      // build items array
      e.preventDefault();
      self.show(this);
    });
  };

  PhotoGallery.DEFAULTS = {
    ui: PhotoSwipeUI_Default,
    pswpElement: '.pswp',
    itemSelector: 'a.gallery__enlarge',
    itemFilter: null,
    captionSelector: '.caption',
  };

  PhotoGallery.prototype.show = function (element) {
    // define options (if needed)
    var options = $.extend({}, this.options, {
      index: this.$items.index($(element))
    });

    // Initializes and opens PhotoSwipe
    var gallery = new PhotoSwipe(this.$pswpElement[0], this.options.ui, this.pswpItems, options);
    gallery.init();
  };

  // PLUGIN DEFINITION
  // ========================
  $.fn.photogallery = function (option) {
    return this.each(function () {
      var $this = $(this);
      var data = $this.data('sr.photogallery');
      var options = typeof option === 'object' && option;

      if (!data) {
        $this.data('sr.photogallery', (data = new PhotoGallery(this, options)));
      }
    });
  };

  $.fn.photogallery.Constructor = PhotoGallery;

})(jQuery, PhotoSwipe);
